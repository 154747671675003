.projects-map {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr)); /* Decreased minimum width */
    gap: 20px;
    width: 90%;
    margin: 0 auto;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.pagination button {
    background-color: #000000;
    color: #fff;
    border: none;
    padding: 10px 20px;
    margin: 0 5px;
    cursor: pointer;
    border-radius: 5px;
}

.pagination button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.pagination .page-link {
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    color: #333;
    padding: 5px 10px;
    text-decoration: none;
    cursor: pointer;
}

.pagination .page-link.active {
    background-color: #000000;
    color: #fff;
    border-color: #000000;
}

@media screen and (max-width: 480px) {
    .projects-map {
        grid-template-columns: repeat(auto-fill, minmax(90%, 1fr));
    }
}
