/* ProjectDetails.css */
.project-details-container {
    margin: 0 50px 0 50px;
    position: relative;
}

.project-details-container .notes-content .responsive-image {
    max-width: 100%;
    height: auto;
}

.project-heading {
    text-align: center;
    margin-bottom: 2rem;
    margin-top: 1%;
}

.project-heading h1 {
    font-size: clamp(1.5rem, 5vw, 2.5rem); /* Dynamically adjust font size */
    font-weight: 700;
    color: #333;
    text-transform: uppercase;
    word-wrap: break-word; /* Ensures long words wrap to the next line */
    text-align: center; /* Center align the text */
}

.status-container {
    position: absolute;
    top: 30px;
    right: 0;
}

.project-status {
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 5px;
    pointer-events: none;
}

.completed {
    background-color: green;
    color: white;
}

.in-progress {
    background-color: orange;
    color: white;
}

.category-container {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
}

.category-title {
    padding: 0 10px 0 10px;
    border-radius: 5px 0 0 5px;
    background: #353535;
    color: white;
}

.category-value {
    padding: 0 10px 0 10px;
    border-radius: 0 5px 5px 0;
    background: #008000;
    color: white;
}

.carousel .slide img {
    height: 100%;
    width: auto;
    max-width: 100%;
    object-fit: contain; /* Adjust this property according to your need */
}

/* Set a fixed height for the carousel */
.slider-container {
    border-radius: 10px;
    background: black;
}

.carousel-root {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Center the Markdown content */
.notes-container {
    margin-top: 20px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    line-height: 1.6;
    font-size: 16px;
    text-align: center; /* Center the content */
}

.non-interactable {
    pointer-events: none;
}

/* Style headings */
.notes-content h1,
.notes-content h2,
.notes-content h3,
.notes-content h4,
.notes-content h5,
.notes-content h6 {
    margin-top: 20px; /* Add space above each heading */
    margin-bottom: 10px; /* Add space below each heading */
}

/* Style paragraphs */
.notes-content p {
    margin-bottom: 10px; /* Add space below each paragraph */
}

/* Style lists */
.notes-content ul,
.notes-content ol {
    padding-left: 20px; /* Indent lists */
    margin-bottom: 10px; /* Add space below lists */
}

/* Style list items */
.notes-content li {
    margin-bottom: 5px; /* Add space between list items */
}

/* Style links */
.notes-content a {
    color: #007bff; /* Set link color */
    text-decoration: none; /* Remove default underline */
}

.notes-content a:hover {
    text-decoration: underline; /* Add underline on hover */
}


@media screen and (max-width: 600px) {
    .project-details-container {
        margin: 0 20px 0 20px;
    }

    .project-heading h1 {
        font-size: 2rem;
    }

    .status-container {
        display: none;
    }

    .category-container {
        display: none;
    }
}
