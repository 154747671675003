.search-container input[type="text"] {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px 0 0 4px;
    /*margin-right: 0.5rem;*/
    width: 100%;
    font: inherit;
}

.search-container input[type="text"]:focus {
    outline: none;
    border: 1px solid #000000;
}


.search-btn {
    padding: 0.54rem 1rem;
    background-color: #353535;
    color: #fff;
    border: none;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
    font: inherit;
}

.search-btn:hover {
    background-color: #000000;
}