.project-card {
    display: flex;
    flex-direction: column;
    gap: 6px;
    /*align-items: center;*/
    width: 100%;
    /*padding: 6px;*/
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    transition: box-shadow 0.3s ease;
    /*margin: 0 auto 20px;*/
    cursor: pointer;
}

.thumbnail-div {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 320px;
    min-height: 300px;
    /*border-radius: 8px;*/
    overflow: hidden;
}

.thumbnail-div:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    backdrop-filter: brightness(0.95);
}

.thumbnail-div img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/*.project-card img {*/
/*    object-fit: cover;*/
/*    aspect-ratio: 16/9;*/
/*}*/


.project-card:hover {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

.project-thumbnail {
    width: 120px;
    height: auto;
    /*margin-right: 20px;*/
    /*border-radius: 8px; !* Add border radius to the image *!*/
    /*use shadow border*/
    /*box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);*/
    transition: transform 0.3s ease; /* Add transition for hover effect */
    pointer-events: none;
}

.project-details {
    /*flex: 1;*/
    /*padding-left: 10px;*/
    padding: 10px;
}

.project-title {
    font-size: 1.2rem;
    margin: 0 0 10px 0;
}

.project-description {
    font-size: 1rem;
    color: #666;
}
